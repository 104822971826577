<template>
  <div>
    <div
      v-for="(carousel, index) in carouseles"
      :key="index++"
      class="row align-items-stretch"
    >
      <div class="col-12 py-3">
        {{ carousel.title }}
      </div>
      <div class="col-6 py-3">
        <div class="shadow border-0 p-0 card">
          <img :src="carousel.image" class=" w-100" />
        </div>
      </div>
      <div class="col-6 py-3">
        <div class="shadow border-0 p-0 card">
          <img :src="carousel.imageAr" class="w-100" />
        </div>
      </div>
      <div class="col-12 py-3">
        <button
          @click="deleteCarousel(carousel.id)"
          class="btn btn-outline-danger"
        >
          Supprimer
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.$store.dispatch("carousel/fetch");
  },
  computed: {
    ...mapGetters("carousel", { carouseles: "getCarouseles" }),
  },
  methods:{
    async deleteCarousel(id) {
      let _id = id;
      await this.$confirm({
        message: "Vous voulez supprimer l\'image ?",
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("carousel/delete", _id);
          }
        },
      });
    },
  }
};
</script>
